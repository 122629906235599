import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Ordonnance = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Ordonnance capillaire</div>

        <div className="capillaire_nouveaute">
          Une nouveauté proposée par le salon L'atelier d'Angel
        </div>

        <div className="capillaire">
          <div className="capillaire_texte">
            <h4>Pour qui?</h4>{" "}
            <p>Toutes personnes ayant rendez-vous au salon de coiffure.</p>
            <br />
            <br />
            <h4>Comment ca se passe? </h4>
            <p>
              Ensemble, nous faisons le point sur la santé de votre cheveu. Je
              vous conseille et je vous dirige dans une gamme de soins
              appropriés à vos cheveux ainsi qu'à votre cuir chevelu.
            </p>
            <br />
            <br />
            <h4>Comment obtenir votre ordonnance?</h4>{" "}
            <p>
              Vous recevrez par Mail, SMS ou Facebook, un lien où vous trouverez
              votre ordonnance à la suite de notre entretien.
            </p>
            <br />
            <br />
            <h4> Quels sont les avantages?</h4>{" "}
            <p>
              *Grâce au code promo, vous bénéficierez d'une remise de 30% sur
              l'ensemble de votre commande. *Les frais de ports sont gratuits
              dés 29 euros d'achat. *Aucune obligation d'achat, vous restez
              maître de votre budget. Vous passez votre commande quand vous le
              souhaitez et vous pouvez ajuster votre panier selon vos envies.
              *Un entretien professionnel et de qualité à votre domicile. Vos
              cheveux sont le fil de votre âme, n'hésitez plus à leur faire
              plaisir!!
            </p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Ordonnance;
