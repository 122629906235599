import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import img01 from "../assets/img/photos/img01.jpg";
import img01small from "../assets/img/photos/img01small.jpg";
import img02 from "../assets/img/photos/img02.jpg";
import img02small from "../assets/img/photos/img02small.jpg";
import img03 from "../assets/img/photos/img03.jpg";
import img03small from "../assets/img/photos/img03small.jpg";
import img04 from "../assets/img/photos/img04.jpg";
import img04small from "../assets/img/photos/img04small.jpg";
import img05 from "../assets/img/photos/img05.jpg";
import img05small from "../assets/img/photos/img05small.jpg";
import img06 from "../assets/img/photos/img06.jpg";
import img06small from "../assets/img/photos/img06small.jpg";
import img07 from "../assets/img/photos/img07.jpg";
import img07small from "../assets/img/photos/img07small.jpg";
import img08 from "../assets/img/photos/img08.jpg";
import img08small from "../assets/img/photos/img08small.jpg";
import img09 from "../assets/img/photos/img09.jpg";
import img09small from "../assets/img/photos/img09small.jpg";
import img10 from "../assets/img/photos/img10.jpg";
import img10small from "../assets/img/photos/img10small.jpg";

const Photos = () => {
  // Déclaration des images, en dehors du return
  const images = [
    {
      original: img01,
      thumbnail: img01small
    },
    {
      original: img02,
      thumbnail: img02small
    },
    {
      original: img03,
      thumbnail: img03small
    },
    {
      original: img04,
      thumbnail: img04small
    },
    {
      original: img05,
      thumbnail: img05small
    },
    {
      original: img06,
      thumbnail: img06small
    },
    {
      original: img07,
      thumbnail: img07small
    },
    {
      original: img08,
      thumbnail: img08small
    },
    {
      original: img09,
      thumbnail: img09small
    },
    {
      original: img10,
      thumbnail: img10small
    }
  ];

  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page">Galerie photo</div>
        {/* Utilisation directe de ImageGallery avec les images */}
        <div className="image_gallery">
          <ImageGallery items={images} />
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Photos;
