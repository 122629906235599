import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import salon1 from "../assets/img/salon1.jpg";
import salon2 from "../assets/img/salon2.jpg";

const Salon = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page"> Le salon</div>
        <div className="mc_container">
          <div className="mc_img">
            {" "}
            <img src={salon1} />{" "}
          </div>
          <div className="mc_txt">
            <h4>Qui suis-je ?</h4>
            <p>
              {" "}
              Bien plus qu’un salon de coiffure de village. Mon salon est un
              véritable endroit accueillant, chaleureux et convivial. Pourquoi
              pousser la porte de mon salon de coiffure? J’aime vous accueillir
              seuls afin de créer une ambiance détendue et intimiste. Grâce à
              cela, nous pouvons discuter librement et je peux me consacrer
              entièrement à votre prestation du début jusqu’à la fin. Fini les
              heures interminables à attendre votre tour pour que l’on s’occupe
              de vous.
            </p>
            <br />
            <h4> Avec quel fournisseur je travaille ?</h4>
            <p>
              {" "}
              Avec son savoir-faire de plus de 60 ans, le Laboratoire DUCASTEL
              avec qui je travaille depuis plus de 6 ans maintenant ne rigole
              pas avec la qualité. Les produits DUCASTEL.PRO sont fiables,
              efficaces et made in LOT (oui, oui, en France) ! Avec son ton
              original et fun, DUCASTEL.PRO n’a pas hésité à CASSER LES CODES !
              Laissez-vous emporter par sa personnalité délirante. Bref, vous
              l’avez compris, DUCASTEL.PRO se définit en 3 mots : A AJOUTER
            </p>
            <br />
            <h4>A quel prix ?</h4>
            <p>
              {" "}
              Qui a dit que qualité devait rimer avec prix élevé ? Je souhaite
              vous donner accès à des produits professionnels de qualité tout en
              gardant des prix abordables. Sympa, non ?
            </p>
            <br />
            <h4> Mais encore ?</h4>
            <p>
              {" "}
              Enseignante à temps plein, j’ai décidé d’ouvrir mon salon de
              coiffure en activité complémentaire car coiffer est une véritable
              passion.
            </p>
            <br />
            <h4> Où me trouver?</h4>
            <p>
              {" "}
              Au 37 rue de la station 1350 Orp/Jauche Comment me contacter ? Par
              téléphone au 0497/322344 ou via messenger.
            </p>
            <br />
            <br />
            <h3> N’hésitez pas à me suivre sur les réseaux sociaux !!!!!!!</h3>
          </div>
          <div className="mc_img">
            {" "}
            <img src={salon2} />{" "}
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Salon;
