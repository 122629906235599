import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Welcome from "../components/Welcome";

const Home = () => {
  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="middle_container">
          <div className="mc_presa"></div>
          <Welcome />
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
