import React from "react";
import img from "../assets/img/welcome.jpg";

const welcome = () => {
  return (
    <div>
      <div className="welcome_container">
        <div className="welcome_container_box">
          <div className="welcome_container_box_left">
            <p>Bienvenue dans le salon de coiffure "L'atelier d'Angèl".</p>
          </div>
          <div className="welcome_container_box_right">
            <img src={img} alt="Image de l'intérieur du salon de coiffure" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default welcome;
