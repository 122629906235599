import React from "react";
import ReactDOM from "react-dom";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";

const Navbar = () => {
  return (
    <div className="navigation">
      <div className="navigation_logo">
        <img src={logo} alt="Logo de l'entreprise" />
      </div>
      <div className="navigation_menu">
        <ul>
          <NavLink to="/">Accueil</NavLink>
          <NavLink to="/salon">Le salon</NavLink>
          <NavLink to="/ordonnance">Ordonnance capillaire</NavLink>
          <NavLink to="/avis">Avis</NavLink>
          {/* <NavLink to="/prestations">Prestations</NavLink> */}
          <NavLink to="/photos">Photos</NavLink>
          <NavLink to="/tarifs">Les tarifs</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </ul>
      </div>
    </div>
  );
};
// ReactDOM.render(<Navbar />, document.getElementById("react-root"));
export default Navbar;
