import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const reviews = [
  {
    id: 1,
    text: "Angèle est une top coiffeuse toujours à l/’écoute et pleine de bons conseils. Ton énergie, ta passion et tes mains de fée font de toi une véritable magicienne des cheveux.",
    author: "Ingrid D."
  },
  {
    id: 2,
    text: "Accueil chaleureux  et toujours avec le sourire...personne solaire  pleine d onde positive...Conseils , professionnalisme, travail  minutieux et impeccable..Angel est toujours de très bon conseil..Je recommande  vraiment !! N hésites pas à pousser la porte du salon ...vous ressortirai  conquise..",
    author: "Laurence G."
  },
  {
    id: 3,
    text: "J ai découvert l'Atelier d'Angel il y a 4 ans pendant la période COVID. Elle a dû rattraper la coupe catastrophique dont une jeune coiffeuse d un autre salon m avait affublée. Angèle est à l'écoute de ses clientes, au petits soins pour elles. Toujours de bons conseils, elle maîtrise parfaitement les techniques et nouveautés de part son statut de professeur. Je recommande souvent son salon pour la gentillesse d'Angel mais aussi pour ses prix très abordables.",
    author: "Laurence D."
  },
  {
    id: 4,
    text: "Je ne peux que recommander Angéline ! Elle est toujours à l'écoute et prend le temps de comprendre ce que vous souhaitez. Que ce soit pour un enfant, un homme ou une femme, elle sait vraiment s'adapter à chacun. Son accueil chaleureux et son professionnalisme font de chaque visite un moment privilégié. On se sent chez soi, et on ressort avec le sourire ! Allez-y les yeux fermés, vous serez enchanté ",
    author: "Julie W."
  }
];

const ReviewBox = ({ text, author, position }) => {
  return (
    <div className={`review_box ${position}`}>
      <p>"{text}"</p>
      <p>
        <strong>- {author}</strong>
      </p>
    </div>
  );
};

const shuffleReviews = (reviewsArray) => {
  return reviewsArray.sort(() => Math.random() - 0.5);
};

const Avis = () => {
  const shuffledReviews = shuffleReviews(reviews);

  return (
    <div className="page">
      <div className="top">
        <Navbar />
      </div>
      <div className="middle">
        <div className="titre_page">Voici l'avis des clients</div>
        <div className="contact_container">
          {/* Vos autres éléments */}

          <div className="avis_section">
            <div className="avis_grid">
              {shuffledReviews.map((review, index) => (
                <ReviewBox
                  key={review.id}
                  text={review.text}
                  author={review.author}
                  position={index % 2 === 0 ? "left" : "right"} // Utilisation correcte de l'index
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <Footer />
      </div>
    </div>
  );
};

export default Avis;
